import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import MuiComponentsProvider from '@sprinx/react-mui-components/MuiComponentsProvider';
import MuiFieldsProvider from '@sprinx/react-mui-fields/MuiFieldsProvider';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Helmet } from 'react-helmet';
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';
import LayoutProvider from './@sprinx/react-after-razzle/LayoutProvider';
import { SseListenerConnect } from './@sprinx/react-after-razzle/sseListener';
import { AppInitializationState, initializeAppState } from './@sprinx/react-after-razzle/stateStore';
import AppComponentLoader from './AppComponentLoader';
import AppGlobalize from './AppGlobalize';
import './api/appState';
import { authState, currencyState, supportedLocaleCodesState } from './api/appState';
import './api/creditNotes/creditNoteDetail';
import './api/creditNotes/creditNotes';
import './api/invoices/invoiceDetail';
import './api/invoices/invoices';
import './api/mainNavigation';
import './api/orders/orderDetail';
import './api/orders/orders';
import './api/products/catalogue';
import './api/products/productParameterGroupTypes';
import './api/products/productParameterTypes';
import './api/products/productParameters';
import './api/products/taxonomyTypes';
import './api/shopping';
import './api/shoppingCart';
import './api/snackbars';
import { showMessageState, useShowSnackbarMessage } from './api/snackbars';
import ShoppingBootstrap from './components/ShoppingBootstrap';
import ShoppingCartDecisionCartInOtherSession from './components/ShoppingCartDecisionCartInOtherSession';
import ShoppingCartRemoteChangeListener from './components/ShoppingCartRemoteChangeListener';
import AppLayout from './layouts/MainLayout';
import themeBasic from './theme';
import themeEurovia from './themeEurovia';

export interface AppProps {
  children: NonNullable<React.ReactNode>;
  initialAppProps: Partial<AppInitializationState<any>>;
}

const App: React.FC<AppProps> = ({ children, initialAppProps }) => {
  return (
    <RecoilRoot initializeState={initializeAppState(initialAppProps)}>
      <Helmet titleTemplate='%s - Blyth' defaultTitle='Blyth' />
      <React.Suspense fallback={<AppComponentLoader />}>
        <AppInitialized>{children}</AppInitialized>
      </React.Suspense>
    </RecoilRoot>
  );
};

App.displayName = 'App';

export default App;

const AppInitialized: React.FC = ({ children }) => {
  const supportedLocales = useRecoilValue(supportedLocaleCodesState);
  const currency = useRecoilValue(currencyState);
  const authUser = useRecoilValue(authState);

  let theme = themeBasic;
  if (authUser?.profile?.customer?.extra?.holding === 'eurovia') {
    theme = themeEurovia;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SseListenerConnect />
      <SnackbarProvider>
        <SnackbarMessageCatcher />
        <AppGlobalize>
          <MuiComponentsProvider>
            <MuiFieldsProvider currencyDefault={currency} supportedLanguages={supportedLocales}>
              <LayoutProvider defaultLayout={AppLayout}>{children}</LayoutProvider>
              <ShoppingBootstrap />
              <ShoppingCartDecisionCartInOtherSession />
              <ShoppingCartRemoteChangeListener />
            </MuiFieldsProvider>
          </MuiComponentsProvider>
        </AppGlobalize>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const SnackbarMessageCatcher = (): null => {
  const showMessage = useShowSnackbarMessage();
  const [msgQ, setMsgQ] = useRecoilState(showMessageState);
  React.useEffect(() => {
    if (msgQ.length > 0) {
      msgQ.forEach((o) => {
        if (o) {
          const { message, ...opts } = o;
          showMessage(message, opts);
        }
      });
      setMsgQ([]);
    }
  }, [msgQ, setMsgQ, showMessage]);
  return null;
};
