import React, { FC, HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';
import { shoppingCustomerSelector } from '../../api/shopping';

export type HeaderTextLogoProps = {} & HTMLAttributes<HTMLDivElement>;

const HeaderTextLogo: FC<HeaderTextLogoProps> = ({ className }) => {
  const customer: any = useRecoilValue(shoppingCustomerSelector);

  const getParentCompanyName = React.useCallback(() => {
    if (customer && customer.type === 'company') {
      return customer.name;
    } else {
      return customer?.parentCompany?.name || '';
    }
  }, [customer]);

  return (
    <span className={className}>
      <span style={{ color: 'white' }}>{getParentCompanyName()}</span>
    </span>
  );
};

HeaderTextLogo.displayName = 'HeaderTextLogo';

export default HeaderTextLogo;
