const messages = {
  cs: {
    invoices: {
      actions: {
        addNewInvoice: 'Přidat novú fakturu',
        all: 'Všechny fakturys',
      },
      listPage: {
        title: 'Faktury',
        table: {
          filter: {
            number: 'Číslo',
          },
          columns: {
            number: 'Číslo',
            invoiceDate: 'Datum vystavení',
            dueDate: 'Datum splatnosti',
            total: 'Celkem',
            totalWithTax: 'Celkem s DPH',
            billingAddress: 'Dodací adresa',
            orderNumber: 'Číslo požadavku',
            unpaidAmount: 'Neuhrazená částka',
            download: 'Stáhnout',
          },
        },
      },
      invoice: {
        newEntity: 'Nová faktura',
        number: {
          label: 'Číslo',
          helperText: '',
          helperTextPreview: '',
        },
        invoiceDate: {
          label: 'Datum vystavení',
          helperText: '',
          helperTextPreview: '',
        },
        total: {
          label: 'Celkem',
          helperText: '',
          helperTextPreview: '',
        },
        totalWithTax: {
          label: 'Celkem s DPH',
          helperText: '',
          helperTextPreview: '',
        },
        billingAddress: {
          label: 'Dodací adresa',
          helperText: '',
          helperTextPreview: '',
        },
        orderNumber: {
          label: 'Číslo požadavku',
          helperText: '',
          helperTextPreview: '',
        },
        deliveryNoteNumber: {
          label: 'Číslo dodacího listu',
          helperText: '',
          helperTextPreview: '',
        },
        paid: {
          label: 'Uhrazená',
          helperText: '',
          helperTextPreview: '',
        },
      },
      scoringBlog: {
        invoiceRowsBlogPreview: {
          title: 'Řádky faktury',
          columns: {
            priceUnit: 'Cena/ks',
          },
        },
      },
    },
  },
};

export default messages;
