import createInitialPropsRedirectToLogin from './createInitialPropsRedirectToLogin';
import { MakeAfterComponentGetInitialProps } from './makeAfterComponent';
import { AfterCtx } from './types';

function preventAuth<M = any>(): (ctx: AfterCtx<M>) => ReturnType<MakeAfterComponentGetInitialProps<{}, M>>;

function preventAuth<M = any>(path: string): (ctx: AfterCtx<M>) => ReturnType<MakeAfterComponentGetInitialProps<{}, M>>;

function preventAuth<InitialProps extends object, M = any>(
  path: string,
  getInitialProps: MakeAfterComponentGetInitialProps<InitialProps, M>,
): (ctx: AfterCtx<M>) => ReturnType<MakeAfterComponentGetInitialProps<InitialProps, M>>;

function preventAuth<InitialProps extends object, M = any>(
  getInitialProps: MakeAfterComponentGetInitialProps<InitialProps, M>,
): (ctx: AfterCtx<M>) => ReturnType<MakeAfterComponentGetInitialProps<InitialProps, M>>;

function preventAuth<InitialProps extends object, M = any>(a?: unknown, b?: unknown): unknown {
  let path: undefined | string;
  let getInitialProps: undefined | MakeAfterComponentGetInitialProps<InitialProps, M>;

  if (a !== undefined) {
    if (typeof a === 'string') {
      path = a;
      getInitialProps = b as undefined | MakeAfterComponentGetInitialProps<InitialProps, M>;
    } else {
      getInitialProps = b as undefined | MakeAfterComponentGetInitialProps<InitialProps, M>;
    }
  }

  return (ctx: AfterCtx<M>) => {
    const appProps = ctx.getAppProps ? ctx.getAppProps() : ctx.initialAppProps;

    // Redirect to HOLDING login if not authenticated
    if (
      getInitialProps !== undefined &&
      appProps.auth?.profile?.parentUser == null &&
      appProps.auth?.profile?.childUsers != null
    ) {
      return Promise.resolve(createInitialPropsRedirectToLogin(ctx, '/hlavni-nakupci'));
    }

    // Redirect to login if not authenticated
    if (!appProps.auth || !appProps.auth.isAuthenticated || !appProps.auth.profile) {
      return Promise.resolve(createInitialPropsRedirectToLogin(ctx, path));
    }
    return getInitialProps ? getInitialProps(ctx) : Promise.resolve({});
  };
}

export default preventAuth;
