import cond from 'lodash/fp/cond';
import pathToRegexp from 'path-to-regexp';
import React from 'react';
import { match as Match, useRouteMatch } from 'react-router-dom';
import { atom, selectorFamily, useSetRecoilState } from 'recoil';
import { GlobalStateRegister } from '../@sprinx/react-after-razzle/stateStore';
import { authState } from './appState';
import { ProductTaxonomyTypeHierarchyNode, catalogueTaxonomiesHierarchySelector } from './products/taxonomyTypes';

export const mainNavigationMatchState = GlobalStateRegister.register(
  atom<Match<any>>({
    key: 'mainNavigationMatch',
    default: {
      isExact: true,
      params: {},
      path: '/',
      url: '/',
    },
  }),
);

export const mainNavigationIsNodeActiveSelector = selectorFamily<boolean, string>({
  key: 'mainNavigationIsNodeActiveSelector',
  get:
    (nodePath) =>
    ({ get }) => {
      const match = get(mainNavigationMatchState);
      const re = pathToRegexp(match.path, undefined, { strict: match.isExact });
      const isMatch = re.exec(nodePath) !== null;

      return cond<string, boolean>([
        [(np) => np.startsWith('/catalogue'), (np) => (isMatch ? match.url.startsWith(np) : false)],
        [(np) => np.startsWith('/special'), (np) => (isMatch ? match.url.startsWith(np) : false)],
        [() => true, () => isMatch],
      ])(nodePath);
    },
});

/**
 * Return filterd fields
 */
export const mainNavigationNodeSelector = selectorFamily<ProductTaxonomyTypeHierarchyNode | undefined, string>({
  key: 'mainNavigationNode',
  get:
    (nodePath) =>
    async ({ get }) => {
      const hierarchy = get(catalogueTaxonomiesHierarchySelector);
      const auth = get(authState);
      const holdingTaxonomy = auth?.profile?.customer?.extra?.holdingTaxonomy || null;

      function traverse(
        parent: string,
        p: string[],
        nodes: ProductTaxonomyTypeHierarchyNode[],
      ): ProductTaxonomyTypeHierarchyNode | undefined {
        const [x, ...rest] = p;
        const cp = `${parent}${x}`;

        return nodes.reduce((acc: ProductTaxonomyTypeHierarchyNode | undefined, n) => {
          if (acc) return acc;
          if (n.code === cp && rest.length === 0) return n;
          if (n.code.startsWith(cp) && rest.length > 0 && n.children && n.children.length > 0) {
            return traverse(`${cp}/`, rest, n.children);
          }
          return undefined;
        }, undefined);
      }

      const [, ...p] = nodePath.split('/');

      // rest of path (without leading)
      if (p.length === 0) return undefined;

      const res = await (async (node) => {
        if (node && node.children && node.children.length) {
          return {
            ...node,
            children:
              node.children &&
              node.children.filter((ch) => ch.hasProducts && (ch.extra.holding || null) === holdingTaxonomy),
          };
        }

        return node;
      })(traverse('/', p, [hierarchy]));

      return res;
    },
});

export const createMainNavigationState = <P extends Record<string, any> = {}>(
  match: Match<P>,
): { presaveState: string[]; propagateToState: { mainNavigationMatch: Match<any> } } => ({
  presaveState: ['mainNavigationMatch'],
  propagateToState: { mainNavigationMatch: match },
});

export const useUpdateMainNavigationMatch = (): void => {
  const match = useRouteMatch();
  const setMainNavigationMatch = useSetRecoilState(mainNavigationMatchState);
  React.useEffect(() => {
    setMainNavigationMatch(match);
  }, [match, setMainNavigationMatch]);
};
