import Link from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCartTwoTone';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import clsx from 'clsx';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authState } from '../../../api/appState';
import LoginPageLogo from '../../../pages/LoginPage/LoginPageLogo';
import LoginPageLogoEurovia from '../../../pages/LoginPage/LoginPageLogoEurovia';
import MainNavigationContentItem from './MainNavigationContentItem';
import MainNavigationContentItemTree from './MainNavigationContentItemTree';

export type MainNavigationContentProps = StandardDivProps<MainNavigationContentClassKey>;

export type MainNavigationContentClassKey =
  | 'root'
  | 'primary'
  | 'secondary'
  | 'termsOfServices'
  | 'logo'
  | 'secondaryDiv'
  | 'supportText'
  | 'supportContainer'
  | 'contactEmail';

const themeSettings = { name: 'MainNavigationContent' };
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles<MainNavigationContentClassKey, {}>({
      root: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
      },
      primary: {
        flexGrow: 1,
        overflow: 'auto',
        paddingTop: theme.spacing(1),
      },
      secondary: {
        minHeight: theme.spacing(18),
      },
      secondaryDiv: {
        paddingTop: theme.spacing(0.5),
        textAlign: 'center',
      },
      termsOfServices: {
        color: 'white',
      },
      logo: {
        backgroundColor: 'white',
        margin: theme.spacing(2),
        padding: theme.spacing(1, 1, 0.25, 1),
        borderRadius: theme.spacing(1),
      },
      supportContainer: {
        color: 'black',
        padding: theme.spacing(0.5, 2, 0.5, 2),
      },
      supportText: {
        backgroundColor: 'white',
        width: '100%',
        fontSize: '1rem',
        padding: theme.spacing(1, 2, 1, 2),
        borderRadius: theme.spacing(0.5),
      },
      contactEmail: {
        textDecoration: 'none',
        color: '#0F3457',
      },
    }),
  themeSettings,
);

const MainNavigationContent = React.forwardRef<HTMLDivElement, MainNavigationContentProps>(
  ({ className, classes: pClasses }, ref) => {
    const classes = useStyles({ classes: pClasses });

    const authUser = useRecoilValue(authState);

    return (
      <div ref={ref} className={clsx(classes.root, className)}>
        <div className={classes.primary}>
          {/* <MainNavigationContentItem icon={<HomeIcon />} primary={'Domov'} to='/' /> */}
          {/* <MainNavigationContentItem icon={<PriorityHighIcon />} primary={'Kampaně'} to='/special/campaign' /> */}
          <MainNavigationContentItemTree primary={'Všechny produkty'} to='/catalogue' />

          {/* <MainNavigationContentItem icon={<AttachMoneyIcon />} primary={'Výprodej'} to='/special/sale' /> */}

          {/* <MainNavigationContentItem icon={<AnnouncementIcon />} primary={'Novinky'} to='/special/introduction' /> */}
          <MainNavigationContentItem
            icon={<AddShoppingCartIcon />}
            primary='Hromadné vložení do košíku'
            to='/hromadny-nakup'
          />
          <MainNavigationContentItem
            icon={<ArrowDownwardIcon />}
            primary='Dokumenty ke stažení'
            to='/dokumenty-ke-stazeni'
          />
          <div className={classes.supportContainer}>
            <div className={classes.supportText}>
              <span>
                <strong>Provozní doba</strong>
                <br />
                Po - Pá 8:00 - 16:30
                <br />
                So - Ne zavřeno
                <br />
                <strong>Infolinka</strong>
                <br />
                Telefon
                <br />
                +420 272 099 544
                <br />
                Email
                <br />
                <a className={classes.contactEmail} href='mailto:prodej@blyth.cz'>
                  prodej@blyth.cz
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className={classes.secondary}>
          {/* <Divider /> */}
          <div className={classes.secondaryDiv}>
            {authUser?.profile?.customer?.extra?.holding === 'eurovia' ? (
              <div className={classes.logo}>
                <LoginPageLogoEurovia />
              </div>
            ) : (
              <>
                <div className={classes.logo}>
                  <LoginPageLogo />
                </div>
                <Link component={RouterLink} to='/obchodni-podminky' color='textPrimary'>
                  <span className={classes.termsOfServices}>Všeobecné obchodní podmínky</span>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
);

MainNavigationContent.displayName = 'MainNavigationContent';

export default withThemeProps(themeSettings)(MainNavigationContent);

// {/* {authUser?.profile?.customer?.extra?.holding === 'eurovia' ? <LoginPageLogoEurovia /> : <LoginPageLogo />} */}
//               {/* {<CompanyLogo />} */}
//               <div>asdf</div>
//               {/* <LoginPageLogo /> */}
