import { ApplicationSettings } from './@sprinx/react-after-razzle';

const applicationSettings: ApplicationSettings = {
  currency: process.env.RAZZLE_SX_DEFAULT_CURRENCY || 'CZK',
  supportedCurrencies: ['CZK', 'EUR'],
  locale: process.env.RAZZLE_SX_LOCALE || 'cs',
  supportedLocales: [
    { locale: 'cs', label: 'Česky' },
    // { code: 'en', label: 'English' },
    // { code: 'sk', label: 'Slovelnsky' },
    // { code: 'pl', label: 'Polska' },
  ],
  pricesType: 'B2B',
  region: process.env.RAZZLE_SX_REGION || '_default',

  // TODO: add to AppProvider
  anonymouseUserId: process.env.RAZZLE_SX_ANONYMOUS_CONTACT_ID || 'anonymouse',
};

export default applicationSettings;
