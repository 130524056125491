import { createStyles, makeStyles } from '@material-ui/core';
import withThemeProps from '@sprinx/react-mui-components/withThemeProps';
import React from 'react';

const themeSettings = { name: 'LoginPageLogo' };

export type LoginPageLogoProps = {
  className?: string;
};

export type LoginPageLogoClassNameKey = 'root';

const useStyles = makeStyles(() =>
  createStyles<LoginPageLogoClassNameKey, {}>({
    root: {
      maxWidth: '310px',
      maxHeight: '76px',
      width: '100%',
      height: '100%',
    },
  }),
);

const LoginPageLogo = React.forwardRef<HTMLOrSVGImageElement, LoginPageLogoProps>(() => {
  const classes = useStyles();
  return (
    <svg
      id='Vrstva_1'
      data-name='Vrstva 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 600.65 146.99'
      className={classes.root}
    >
      <path
        style={{ fill: '#002d59' }}
        d='M336.49,275.39h13.28c4.57,0,7.78.79,9.6,2.36a7.87,7.87,0,0,1,2.74,6.26,8.56,8.56,0,0,1-2.76,6.68q-2.78,2.47-9.53,2.48H336.49Zm0-33.35H348q6,0,8.34,2.16a7.64,7.64,0,0,1,2.4,6,8.09,8.09,0,0,1-2.4,6.31q-2.39,2.19-8.51,2.19H336.49Zm-26.37,70h45.13a110.14,110.14,0,0,0,11.71-1.2c4.43-.6,7.73-1.56,9.93-2.87a23.06,23.06,0,0,0,8.36-8.62,24.65,24.65,0,0,0,3.06-12.28q0-8.37-4.3-13.92t-13.41-7.81a20.64,20.64,0,0,0,9.11-5.86,20.1,20.1,0,0,0,4.68-13.35,20.74,20.74,0,0,0-6.55-15.57q-6.55-6.28-18.78-6.29H310.12Z'
        transform='translate(-120.56 -224.21)'
      />
      <polygon
        style={{ fill: '#002d59' }}
        points='278.84 87.82 345.78 87.82 345.78 66.2 304.98 66.2 304.98 0.05 278.84 0.05 278.84 87.82'
      />
      <path
        style={{ fill: '#002d59' }}
        d='M638,312h26.14v-35.5H692.7V312H719V224.26H692.7V255H664.14V224.26H638Zm-91.4-66.09h26.66V312H599.4V245.94h26.66V224.26H546.6ZM485,275.27V312h26.19V275.27l32.9-51H515.24l-17.08,29.61-17-29.61h-29Z'
        transform='translate(-120.56 -224.21)'
      />
      <path
        style={{ fill: '#65b32e' }}
        d='M691.66,336.58h6c2.48,0,4.16.37,5.06,1.1a4.2,4.2,0,0,1,.5,5.62,3.64,3.64,0,0,1-2.21,1.34,21.63,21.63,0,0,1-3.59.58h-5.72Zm0,33.9V353.24h1.2a5.84,5.84,0,0,1,3.32,1,10.06,10.06,0,0,1,2.46,3.28l7.25,13h15.33l-6.57-12.31a17.14,17.14,0,0,0-1.9-2.64,13.54,13.54,0,0,0-2.17-2.23,14.17,14.17,0,0,0-3.53-1.57,16.59,16.59,0,0,0,4.76-1.68,11.92,11.92,0,0,0,4.28-4.15,12.69,12.69,0,0,0-.47-13,10.24,10.24,0,0,0-5.36-3.87,33.75,33.75,0,0,0-9.61-1H678v42.47Zm-47-16.2,4.8-15.26,4.84,15.26Zm14.65,16.2h14.21L657.05,328H642.26l-16.5,42.47h13.85l2.14-7h15.4Zm-73.49,0h37v-9.62H599.44v-8.37h21.11v-8.67H599.44v-6.75H622.2V328H585.85Zm-56.05,0h13.3l7.67-26.74,7.7,26.74h13.3L581.44,328H568.63L564,351.75,557.18,328H544.34l-6.79,23.77L532.92,328H520Zm-56.27,0H487.1V360.11l7-7.1,9.26,17.47h16.7L503.33,344l16-16h-18l-14.2,16.05V328H473.53Zm-33.95-33.9h6q3.7,0,5.06,1.1a4.21,4.21,0,0,1,.49,5.62,3.61,3.61,0,0,1-2.2,1.34,21.79,21.79,0,0,1-3.59.58h-5.72Zm0,33.9V353.24h1.2a5.88,5.88,0,0,1,3.32,1,9.93,9.93,0,0,1,2.45,3.28l7.25,13h15.33l-6.57-12.31a16.47,16.47,0,0,0-1.9-2.64,13.14,13.14,0,0,0-2.17-2.23,13.83,13.83,0,0,0-3.53-1.57,16.55,16.55,0,0,0,4.77-1.68A12.09,12.09,0,0,0,464,345.9a12.66,12.66,0,0,0-.48-13,10.2,10.2,0,0,0-5.36-3.87,33.7,33.7,0,0,0-9.61-1H426v42.47ZM389,340a8.36,8.36,0,0,1,6.58-2.81A8.65,8.65,0,0,1,402.4,340q2.5,2.76,2.5,8.76,0,7.13-2.39,9.88a9.57,9.57,0,0,1-13.49-.06q-2.47-2.81-2.47-9.24T389,340Zm-13,21.64a18.28,18.28,0,0,0,7.89,7.24,28.81,28.81,0,0,0,12.29,2.29,25.34,25.34,0,0,0,12.2-2.65,18.16,18.16,0,0,0,7.49-7.42q2.6-4.75,2.59-12.21,0-10.25-5.93-15.94t-16.89-5.7q-10.68,0-16.67,5.8t-6,16.19Q373,356.73,376,361.67Zm-56.35,8.81H333l7.67-26.74,7.7,26.74h13.3L371.31,328H358.49l-4.64,23.74L347,328H334.21l-6.79,23.77L322.78,328H309.9Z'
        transform='translate(-120.56 -224.21)'
      />
      <rect style={{ fill: '#1c3156' }} width='150.58' height='53.63' />
      <rect style={{ fill: '#70b52c' }} y='92.66' width='150.58' height='53.54' />
      <path
        style={{ fillRule: 'evenodd', fill: '#1c3156' }}
        d='M150.62,304.08c0,6-6.59,8-12.78,8H120.56V283.28h14.79c6.68,0,13.65,1.24,13.65,7.92a5.93,5.93,0,0,1-4.58,5.82v.19c3.81,1.14,6.2,3.43,6.2,6.87m-13.17-11.93c-.38-2.19-2.38-2-4.29-2h-.86v4.2h1.24C135.35,294.35,137.74,294.16,137.45,292.15Zm1.43,10.59c0-2.58-3.34-2.38-5.34-2.38H132.3v4.77h1.43C135.83,305.13,138.88,305.61,138.88,302.74Z'
        transform='translate(-120.56 -224.21)'
      />
      <path
        style={{ fillRule: 'evenodd', fill: '#1c3156' }}
        d='M181.82,300.26c0,8.4-6,12.22-15.07,12.22s-15.18-3.82-15.18-12.22V283.18h11.84v16.13c0,2.19.09,4.19,3.34,4.19,3.05,0,3.14-2,3.14-4.19V283.18h11.93Z'
        transform='translate(-120.56 -224.21)'
      />
      <polygon
        style={{ fillRule: 'evenodd', fill: '#1c3156' }}
        points='96.09 87.88 84.36 87.88 74.05 73.47 74.05 87.88 62.41 87.88 62.41 58.97 74.05 58.97 84.36 73.47 84.36 58.97 96.09 58.97 96.09 87.88'
      />
      <path
        style={{ fillRule: 'evenodd', fill: '#1c3156' }}
        d='M248.53,283.27l-14.22,20h13.17v8.88H215.32S229,292.72,230,291.49H218.18v-8.22Z'
        transform='translate(-120.56 -224.21)'
      />
      <polygon
        style={{ fillRule: 'evenodd', fill: '#1c3156' }}
        points='150.58 87.88 128.92 87.88 128.92 59.06 140.65 59.06 140.65 78.62 150.58 78.62 150.58 87.88'
      />
    </svg>
  );
});

LoginPageLogo.displayName = 'LoginPageLogo';

export default withThemeProps(themeSettings)(LoginPageLogo);
